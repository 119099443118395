const somenteNumeros = (valor) => {
  return valor.replace(/[^\d]+/g, '');
};

const somenteNumerosFlutuantes = (valor) => {
  var v = valor;
  v = v.replace(/\D/g,'')
  v = v.replace(/(\d{1})(\d{1,3})$/, "$1,$2")
  v = v.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
  v = v.replace(/^(\d)/g,"$1")
  return v;
};

export default {
  somenteNumeros,
  somenteNumerosFlutuantes,
};
