const isValid = value => {
  if (
    value &&
    value.match(new RegExp("[0-9]{2}[A-Za-z]{2}[0-9]{9}-[0-9]{1}")) == null
  ) {
    return false;
  }
  return true;
};

export default {
  isValid
};
