<template>
  <div class="card mb-3">
    <div class="card-body">
      <div class="row">
        <div class="col-lg-2 mb-3">
          <label class="form-label required">Tipo</label>
          <div>
            <div
              class="form-check form-check-inline"
              v-for="(tipo, index) in tipoContainer"
              :key="index"
              @click="definirMascaraInputNumero(tipo.sigla)"
            >
              <input
                class="form-check-input"
                type="radio"
                name="tipoContainer"
                :id="'tipoContainer' + tipo.sigla"
                :value="tipo.sigla"
                v-model="container.tipo"
              />
              <label
                class="form-check-label"
                :for="'tipoContainer' + tipo.sigla"
                >{{ tipo.descricao }}</label
              >
            </div>
          </div>
        </div>

        <div class="col-lg-4 mb-3">
          <label for="containerNumero" class="form-label required"
            >Nro. Contêiner</label
          >
          <input
            type="text"
            id="containerNumero"
            v-model="container.numero"
            class="form-control"
            :disabled="container.tipo == null"
            :placeholder="inputNumero.placeholder"
            :maxlength="inputNumero.maxlength"
            v-mask="inputNumero.mask"
            :input-attrs="
              $v.container.numero.$error
                ? { class: 'form-control is-invalid' }
                : { class: 'form-control' }
            "
            @blur="
              $v.container.numero.$touch();
              validarMascaraNumeroContainer();
            "
            @keyup="
              definirMascaraInputNumero(null);
              toUpperCaseNumeroContainer();
            "
            :state="$v.container.numero.$error ? false : null"
          />
          <b-form-invalid-feedback
            v-if="$v.container.numero.$error"
            :state="$v.container.numero.$error ? false : null"
          >
            <div v-if="!$v.container.numero.required">
              É necessário informar o número do Contêiner
            </div>
          </b-form-invalid-feedback>
        </div>

        <div class="col-lg-3 mb-3">
          <label for="teu" class="form-label required"> TEU </label>
          <v-autocomplete
            id="teu"
            :items="autoSelectTeu.items"
            v-model="container.teu"
            :get-label="getLabelAutoSelectTeu"
            @update-items="updateItemsAutoSelectTeu"
            :disabled="container.tipo == null"
            :component-item="autoSelectTeu.template"
            :min-len="autoSelectTeu.length"
            :auto-select-one-item="autoSelectTeu.autoSelectOneItem"
            :input-attrs="
              $v.container.teu.$error
                ? { class: 'form-control is-invalid' }
                : { class: 'form-control' }
            "
            @blur="$v.container.teu.$touch()"
            :state="$v.container.teu.$error ? false : null"
          >
          </v-autocomplete>
          <b-form-invalid-feedback
            v-if="$v.container.teu.$error"
            :state="$v.container.teu.$error ? false : null"
          >
            <div v-if="!$v.container.teu.required">
              É necessário informar a unidade TEU
            </div>
          </b-form-invalid-feedback>
        </div>

        <div class="col-lg-3 mb-3">
          <label for="mercadoria" class="form-label required">
            Mercadoria
          </label>
          <v-autocomplete
            id="mercadoria"
            :items="autoSelectMercadoria.items"
            v-model="container.mercadoria"
            :get-label="getLabelAutoSelectMercadoria"
            :disabled="container.tipo == null"
            @update-items="updateItemsAutoSelectMercadoria"
            :component-item="autoSelectTeu.template"
            :min-len="autoSelectTeu.length"
            :auto-select-one-item="autoSelectMercadoria.autoSelectOneItem"
            :input-attrs="
              $v.container.mercadoria.$error
                ? { class: 'form-control is-invalid' }
                : { class: 'form-control' }
            "
            @blur="$v.container.mercadoria.$touch()"
            :state="$v.container.mercadoria.$error ? false : null"
          >
          </v-autocomplete>
          <b-form-invalid-feedback
            v-if="$v.container.mercadoria.$error"
            :state="$v.container.mercadoria.$error ? false : null"
          >
            <div v-if="!$v.container.mercadoria.required">
              É necessário informar uma mercadoria
            </div>
          </b-form-invalid-feedback>
        </div>

        <div class="col-lg-3 mb-3">
          <label for="pesoBruto" class="form-label required">Peso Bruto</label>
          <b-form-input
            id="pesoBruto"
            v-model="container.pesoBruto"
            type="text"
            :disabled="container.tipo == null"
            @blur="$v.container.pesoBruto.$touch()"
            @keyup="validarPeso()"
            :state="$v.container.pesoBruto.$error ? false : null"
          />
          <b-form-invalid-feedback
            v-if="$v.container.pesoBruto.$error"
            :state="$v.container.pesoBruto.$error ? false : null"
          >
            <div v-if="!$v.container.pesoBruto.required">
              É necessário informar o peso bruto do contêiner
            </div>
          </b-form-invalid-feedback>
        </div>

        <div class="col-lg-3 mb-3">
          <label for="dataEntrada" class="form-label required"
            >Data Entrada</label
          >
          <b-form-input
            id="dataEntrada"
            v-model="container.dataEntrada"
            type="text"
            :disabled="container.tipo == null"
            v-maska="'##/##/#### ##:##'"
            @blur="$v.container.dataEntrada.$touch()"
            :state="$v.container.dataEntrada.$error ? false : null"
          />
          <b-form-invalid-feedback
            v-if="$v.container.dataEntrada.$error"
            :state="$v.container.dataEntrada.$error ? false : null"
          >
            <div v-if="!$v.container.dataEntrada.required">
              É necessário informar a data de entrada
            </div>
            <div v-if="!$v.container.dataEntrada.mascaraDataEntradaInvalid">
              Informe a data e hora no padrão DD/MM/YYYY HH:MM
            </div>
          </b-form-invalid-feedback>
        </div>
      </div>

      <div>
        <button
          type="button"
          class="btn btn-success"
          :disabled="$v.container.$invalid"
          @click="save()"
        >
          <i class="fas fa-check"></i> Salvar Contêiner
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { TipoContainer } from "@/domain/enums.js";
import Autocomplete from "v-autocomplete";
import ItemTemplateAutoSelectTeu from "../components/ItemTemplateAutoSelectTeu.vue";
import ItemTemplateAutoSelectMercadoria from "../components/ItemTemplateAutoSelectMercadoria.vue";
import { required } from "vuelidate/lib/validators";
import StringUtil from "@/util/string";
import MascaraDataEntrada from "@/validators/movimentacoes/mascaraDataEntrada";
import { TeuService } from "@/services";
import { MercadoriaService } from "@/services";
import moment from "moment";
import ToastHelper from "@/helpers/toastHelper";
import InputUtil from "@/util/inputUtil.js";

export default {
  components: {
    "v-autocomplete": Autocomplete,
  },
  data: function () {
    return {
      rules: null,
      autoSelectTeu: {
        length: 1,
        autoSelectOneItem: false,
        items: [],
        template: ItemTemplateAutoSelectTeu,
      },
      autoSelectMercadoria: {
        length: 1,
        autoSelectOneItem: false,
        items: [],
        template: ItemTemplateAutoSelectMercadoria,
      },
      containerTipoContainerSigla: TipoContainer.CONTAINER.sigla,
      inputNumero: {
        placeholder: "",
        maxlength: "",
        mask: "AAA#X",
      },
      container: {
        tipo: null,
        numero: "",
        teu: null,
        mercadoria: null,
        pesoBruto: null,
        dataEntrada: "",
        dataPerdimento: "",
      },
    };
  },
  validations: {
    container: {
      numero: {
        required,
      },
      teu: { required },
      mercadoria: { required },
      pesoBruto: { required },
      dataEntrada: {
        required,
        mascaraDataEntradaInvalid: (value) => MascaraDataEntrada.isValid(value),
      },
    },
  },
  created() {
    this.tipoContainer = TipoContainer;
    this.preencheDataEntrada();
  },
  methods: {
    validarMascaraNumeroContainer() {
      let tipoContainer = this.container.tipo;
      let tipoPlacaMercosul = false;
      if (
        tipoContainer == TipoContainer.CONTAINER.sigla &&
        this.container.numero.match(
          new RegExp("[a-zA-Z]{3}-[0-9]{3}.[0-9]{3}-[0-9]{1}")
        ) == null
      ) {
        this.container.numero = "";
      }

      if (
        this.container.numero.match(new RegExp("[a-zA-Z]{3}[0-9]{1}")) != null
      ) {
        tipoPlacaMercosul = true;
      }

      if (
        tipoContainer == TipoContainer.PLACA.sigla &&
        this.container.numero.match(new RegExp("[a-zA-Z]{3}-[0-9]{4}")) ==
          null &&
        !tipoPlacaMercosul
      ) {
        this.container.numero = "";
      }

      if (
        tipoContainer == TipoContainer.PLACA.sigla &&
        this.container.numero.match(
          new RegExp("[a-zA-Z]{3}[0-9]{1}[a-zA-Z]{1}[0-9]{2}")
        ) == null &&
        tipoPlacaMercosul
      ) {
        this.container.numero = "";
      }
    },
    toUpperCaseNumeroContainer() {
      this.container.numero = this.container.numero.toUpperCase();
    },
    definirMascaraInputNumero(sigla) {
      let tipoContainer = this.container.tipo;
      if (sigla != null) {
        tipoContainer = sigla;
      }
      let numero = this.container.numero.replace(/[^a-zA-Z0-9]+/g, "");
      if (tipoContainer == TipoContainer.CONTAINER.sigla) {
        this.inputNumero.mask = "AAAA-###.###-#";
        this.inputNumero.placeholder = "AAAA-999.999-9";
      } else {
        this.inputNumero.mask = "AAA#X";
        this.inputNumero.placeholder = "AAA-9999 ou AAA9A99";
        if (numero.match(new RegExp("[a-zA-Z]{3}[0-9]{1}[a-zA-Z]{1}"))) {
          this.inputNumero.mask = "AAA#X##";
        } else if (numero.match(new RegExp("[a-zA-Z]{3}[0-9]{2}"))) {
          this.inputNumero.mask = "AAA-####";
        }
      }
    },
    definirPlaceholderInputNumero(tipo) {
      if (tipo == TipoContainer.CONTAINER.sigla) {
        this.inputNumero.placeholder = "AAAA-999.999-9";
      } else {
        this.inputNumero.placeholder = "AAA-9999 ou AAA9A99";
      }
    },
    validarPeso() {
      this.container.pesoBruto = InputUtil.somenteNumerosFlutuantes(
        this.container.pesoBruto,
        console.log('Após validar'),
        console.log(this.container.pesoBruto)
      );
    },
    preencheDataEntrada() {
      let d = new Date();
      this.container.dataEntrada =
        StringUtil.preencherCaractereEsquerda({
          valor: d.getDate(),
          total: 2,
          caractere: 0,
        }) +
        "/" +
        StringUtil.preencherCaractereEsquerda({
          valor: d.getMonth() + 1,
          total: 2,
          caractere: 0,
        }) +
        "/" +
        d.getFullYear() +
        " " +
        StringUtil.preencherCaractereEsquerda({
          valor: d.getHours(),
          total: 2,
          caractere: 0,
        }) +
        ":" +
        StringUtil.preencherCaractereEsquerda({
          valor: d.getMinutes(),
          total: 2,
          caractere: 0,
        });
    },
    calcularDataPerdimento(dataEntrada) {
      let dataPerdimento = moment(dataEntrada, "DD/MM/YYYY H:mm")
        .add(90, "days")
        .format("DD/MM/YYYY H:mm");
      let arrayDataPerdimento = dataPerdimento.split(" ");
      let arrayHoraDataPerdimento = arrayDataPerdimento[1].split(":");
      dataPerdimento =
        arrayDataPerdimento[0] +
        " " +
        StringUtil.preencherCaractereEsquerda({
          valor: arrayHoraDataPerdimento[0],
          total: 2,
          caractere: 0,
        }) +
        ":" +
        StringUtil.preencherCaractereEsquerda({
          valor: arrayHoraDataPerdimento[1],
          total: 2,
          caractere: 0,
        });
      return dataPerdimento;
    },
    setData(container) {
      this.container = {
        tipo: container.tipo,
        numero: container.numero,
        teu: container.teu,
        mercadoria: container.mercadoria,
        pesoBruto: container.pesoBruto.toString(),
        dataEntrada: container.dataEntrada,
      };
      this.validarPeso()
      this.autoSelectTeu.items.push(container.teu);
      this.autoSelectMercadoria.items.push(container.mercadoria);
    },
    reset() {
      this.container = {
        tipo: null,
        numero: "",
        teu: null,
        mercadoria: null,
        pesoBruto: null,
        dataEntrada: "",
        dataPerdimento: "",
      };
      this.inputNumero.placeholder = "";
      this.preencheDataEntrada();
      this.$v.$reset();
    },
    dataEntradaIsValid(data) {
      return moment(data, "DD/MM/YYYY H:mm").isValid();
    },
    save() {
      if (this.dataEntradaIsValid(this.container.dataEntrada)) {
        this.container.dataPerdimento = this.calcularDataPerdimento(
          this.container.dataEntrada
        );
        this.container.pesoBruto = parseFloat(this.container.pesoBruto.replaceAll(".","").replace(",",".")).toFixed(3);
        this.$emit("change", this.container);
      } else {
        ToastHelper.dangerMessage("Data de entrada do Contêiner inválida");
      }
    },
    getLabelAutoSelectTeu(item) {
      return item == null ? null : item.descricao;
    },
    updateItemsAutoSelectTeu(query) {
      this.autoSelectTeu.items = [];
      TeuService.get({
        currentPage: 0,
        pageSize: 15,
        descricao: query,
      }).then((resposta) => {
        resposta.data.content.forEach((teu) => {
          this.autoSelectTeu.items.push({
            ...teu,
          });
        });
      });
    },
    getLabelAutoSelectMercadoria(item) {
      return item == null ? null : item.descricao;
    },
    updateItemsAutoSelectMercadoria(query) {
      this.autoSelectMercadoria.items = [];
      MercadoriaService.get({
        currentPage: 0,
        pageSize: 15,
        descricao: query,
      }).then((resposta) => {
        resposta.data.content.forEach((teu) => {
          this.autoSelectMercadoria.items.push({
            ...teu,
          });
        });
      });
    },
  },
};
</script>
