<template>
  <b-form
    @submit.prevent="change"
    class="w-100 h-100 p-4 d-flex flex-column justify-content-between"
  >
    <validation-observer ref="movimentacao">
      <div class="row">
        <div class="col-lg-8 mb-3">
          <label for="empresa" class="form-label required">Empresa</label>
          <v-autocomplete
            id="empresa"
            :items="autoSelectEmpresa.items"
            v-model="movimentacao.empresa"
            :get-label="getLabelAutoSelectEmpresa"
            @update-items="updateItemsAutoSelectEmpresa"
            :component-item="autoSelectEmpresa.template"
            :min-len="autoSelectEmpresa.length"
            :auto-select-one-item="autoSelectEmpresa.autoSelectOneItem"
            :input-attrs="
              $v.movimentacao.empresa.$error
                ? { class: 'form-control is-invalid' }
                : { class: 'form-control' }
            "
            @blur="$v.movimentacao.empresa.$touch()"
            :state="$v.movimentacao.empresa.$error ? false : null"
          >
          </v-autocomplete>
          <b-form-invalid-feedback
            v-if="$v.movimentacao.empresa.$error"
            :state="$v.movimentacao.empresa.$error ? false : null"
          >
            <span v-if="!$v.movimentacao.empresa.required"
              >É necessário informar a empresa</span
            >
          </b-form-invalid-feedback>
        </div>

        <div class="col-lg-4 mb-3">
          <label for="dta" class="form-label required">DTA</label>
          <b-form-input
            id="dta"
            v-model="movimentacao.dta"
            type="text"
            v-maska="'##/#######-#'"
            placeholder="00/0000000-0"
            @blur="$v.movimentacao.dta.$touch()"
            :state="$v.movimentacao.dta.$error ? false : null"
          />
          <b-form-invalid-feedback
            v-if="$v.movimentacao.dta.$error"
            :state="$v.movimentacao.dta.$error ? false : null"
          >
            <div v-if="!$v.movimentacao.dta.required">
              É necessário informar o DTA
            </div>
            <div v-if="!$v.movimentacao.dta.validMascaraDTA">
              Informe um número de DTA válido
            </div>
          </b-form-invalid-feedback>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-4 mb-3">
          <label for="due" class="form-label">DU-E</label>
          <b-form-input
            v-model="movimentacao.due"
            placeholder="99AA999999999-9"
            v-mask="'##AA#########-#'"
            @blur="$v.movimentacao.due.$touch()"
            :state="$v.movimentacao.due.$error ? false : null"
            @keyup="habilitarArmazenamentoMovimentacao()"
          ></b-form-input>
          <b-form-invalid-feedback
            v-if="$v.movimentacao.due.$error"
            :state="$v.movimentacao.due.$error ? false : null"
          >
            <div v-if="!$v.movimentacao.due.validMascaraDUE">
              Informe uma DU-E válida
            </div>
          </b-form-invalid-feedback>
        </div>

        <div class="col-lg-4 mb-3">
          <label for="declaracaoImportacao" class="form-label"
            >Declaração de Importação</label
          >
          <b-form-input
            v-model="movimentacao.declaracaoImportacao"
            placeholder="99/9999999-9"
            v-mask="'##/#######-#'"
            @blur="$v.movimentacao.declaracaoImportacao.$touch()"
            :state="$v.movimentacao.declaracaoImportacao.$error ? false : null"
            @keyup="habilitarArmazenamentoMovimentacao()"
          ></b-form-input>
          <b-form-invalid-feedback
            v-if="$v.movimentacao.declaracaoImportacao.$error"
            :state="$v.movimentacao.declaracaoImportacao.$error ? false : null"
          >
            <div
              v-if="
                !$v.movimentacao.declaracaoImportacao.validMascaraDTAImportacao
              "
            >
              Informe uma declaração de importação válida
            </div>
          </b-form-invalid-feedback>
        </div>

        <div class="col-lg-4 mb-3">
          <label
            for="armazenamento"
            class="form-label"
            v-if="!inputDestinoObrigatorio"
            >Destino</label
          >
          <label
            for="armazenamento"
            class="form-label required"
            v-if="inputDestinoObrigatorio"
            >Destino</label
          >
          <b-form-select
            id="armazenamento"
            :options="optionsLocaisArmazenamentoEA"
            :disabled="inputDestinoDesabilitado || editando"
            v-model="movimentacao.armazenamento"
            :state="inputDestinoInvalido ? false : null"
            @change="selecionarObjetoArmazenamento()"
          ></b-form-select>
          <b-form-invalid-feedback
            v-if="inputDestinoInvalido"
            :state="inputDestinoInvalido ? false : null"
          >
            <div v-if="inputDestinoInvalido">Destino deve ser informado</div>
          </b-form-invalid-feedback>
        </div>
      </div>
    </validation-observer>

    <div class="card mb-3">
      <div class="card-body">
        <h5 class="card-title">Contêiners</h5>

        <table class="table table-hover table-sm table-bordered">
          <thead>
            <tr>
              <th scope="col">Nro Contêiner</th>
              <th scope="col">Mercadoria</th>
              <th scope="col">Peso Bruto</th>
              <th scope="col">Data Entrada</th>
              <th scope="col">Data Perdimento</th>
              <th scope="col">Data Saída</th>
              <th scope="col">Local</th>
              <th scope="col" class="acoes-icones">Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(container, index) in movimentacao.containers"
              :key="index"
            >
              <td>{{ container.numero }}</td>
              <td>
                {{ container.mercadoria ? container.mercadoria.descricao : "" }}
              </td>
              <td>{{ validarPeso(container.pesoBruto.toString()) }}</td>
              <td>{{ container.dataEntrada }}</td>
              <td>{{ container.dataPerdimento }}</td>
              <td>{{ container.dataSaida }}</td>
              <td>
                <span
                  v-if="
                    movimentacao.armazenamento == null &&
                      container.local == null
                  "
                  >Pátio</span
                >
                <span
                  v-else-if="
                    movimentacao.armazenamento != null &&
                      container.local == undefined
                  "
                  >{{ textoLocalArmazenamento }}</span
                >
                <span v-else>{{ container.local.descricao }}</span>
              </td>
              <td>
                <button
                  type="button"
                  class="btn btn-primary btn-sm"
                  @click="editarContainer(container, index)"
                  v-if="!editando"
                >
                  <i class="fas fa-pencil-alt"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-danger btn-sm"
                  @click="excluirContainer(index)"
                  v-if="!container.dataSaida"
                >
                  <i class="fas fa-trash-alt"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <containerForm @change="saveContainer" ref="containerForm"></containerForm>

    <div class="pt-4 w-100 d-flex justify-content-center">
      <router-link class="btn btn-light" :to="{ name: 'MovimentacaoPesquisar' }"
        ><i class="fas fa-times"></i> Cancelar</router-link
      >
      <button
        type="submit"
        class="btn btn-success"
        :disabled="$v.movimentacao.$invalid"
      >
        <i class="fas fa-check"></i> Salvar
      </button>
    </div>
  </b-form>
</template>

<script>
import formatarCNPJ from "@/util/formatacao/formatar_cnpj.js";
import Autocomplete from "v-autocomplete";
import ItemTemplateAutoSelectEmpresa from "../ItemTemplateAutoSelectEmpresa.vue";
import { required } from "vuelidate/lib/validators";
import MinimoContainer from "@/validators/movimentacoes/minimoContainer";
import MascaraDTA from "@/validators/movimentacoes/mascaraDTA";
import mascaraDTAImportacao from "@/validators/movimentacoes/mascaraDTAImportacao";
import mascaraDUE from "@/validators/movimentacoes/mascaraDUE";
import ContainerForm from "../components/ContainerForm";
import { EmpresaService } from "@/services";
import { ArmazenamentoService } from "@/services/armazenamentoService";
import ToastHelper from "@/helpers/toastHelper";
import InputUtil from "@/util/inputUtil.js";

export default {
  props: {
    mountedForm: {
      type: Object
    },
    editando: {
      type: Boolean
    }
  },
  components: { "v-autocomplete": Autocomplete, containerForm: ContainerForm },
  data: function() {
    return {
      numeroContainerEdicao: null,
      loadedIndexContainer: null,
      optionsLocaisArmazenamentoEA: [],
      inputDestinoObrigatorio: false,
      inputDestinoInvalido: false,
      inputDestinoDesabilitado: true,
      textoLocalArmazenamento: null,
      autoSelectEmpresa: {
        length: 1,
        autoSelectOneItem: false,
        items: [],
        template: ItemTemplateAutoSelectEmpresa
      },
      movimentacao: {
        empresa: null,
        dta: "",
        containers: [],
        due: "",
        declaracaoImportacao: "",
        armazenamento: null
      }
    };
  },
  validations: {
    movimentacao: {
      empresa: { required },
      dta: {
        required,
        validMascaraDTA: value => MascaraDTA.isValid(value)
      },
      due: {
        validMascaraDUE: value => mascaraDUE.isValid(value)
      },
      declaracaoImportacao: {
        validMascaraDTAImportacao: value => mascaraDTAImportacao.isValid(value)
      },
      containers: { validContainer: value => MinimoContainer.isValid(value) }
    }
  },
  methods: {
    validarPeso(v) {
      v = InputUtil.somenteNumerosFlutuantes(v);
      return v;
    },
    selecionarObjetoArmazenamento() {
      this.optionsLocaisArmazenamentoEA.forEach(local => {
        if (local.value == this.movimentacao.armazenamento) {
          this.textoLocalArmazenamento = local.text;
          this.inputDestinoInvalido = false;
        }
      });
    },
    validarInputDestino() {
      if (
        this.movimentacao.due.length > 0 &&
        !this.$v.movimentacao.due.validMascaraDUE
      ) {
        return false;
      }

      if (
        this.movimentacao.due.length > 0 &&
        !this.$v.movimentacao.due.validMascaraDUE
      ) {
        return false;
      }

      return true;
    },
    habilitarArmazenamentoMovimentacao() {
      if (
        (this.movimentacao.due.length > 0 &&
          this.$v.movimentacao.due.validMascaraDUE) ||
        (this.movimentacao.declaracaoImportacao.length > 0 &&
          this.$v.movimentacao.declaracaoImportacao.validMascaraDTAImportacao)
      ) {
        this.inputDestinoDesabilitado = false;
        this.inputDestinoObrigatorio = true;
      } else {
        this.inputDestinoDesabilitado = true;
        this.inputDestinoObrigatorio = false;
        this.inputDestinoInvalido = false;
        this.movimentacao.armazenamento = null;
      }
    },
    getLabelAutoSelectEmpresa(item) {
      return item == null ? null : item.razaoSocial;
    },
    updateItemsAutoSelectEmpresa(query) {
      this.autoSelectEmpresa.items = [];
      EmpresaService.getEmpresas({
        currentPage: 0,
        pageSize: 15,
        razaoSocial: query
      }).then(resposta => {
        resposta.data.content.forEach(empresa => {
          this.autoSelectEmpresa.items.push({
            ...empresa
          });
        });
      });
    },
    editarContainer(container, index) {
      this.$refs.containerForm.setData(container);
      this.$refs.containerForm.definirMascaraInputNumero(container.tipo);
      this.loadedIndexContainer = index;
      this.numeroContainerEdicao = container.numero;
    },
    excluirContainer(index) {
      this.movimentacao.containers.splice(index, 1);
    },
    formatarCNPJ(value) {
      this.empresa.cnpj = formatarCNPJ(value);
    },
    reset() {
      this.movimentacao = {
        empresa: null,
        dta: "",
        containers: [],
        due: "",
        declaracaoImportacao: "",
        armazenamento: null
      };
      this.$v.$reset();
    },
    change() {
      if (
        (this.inputDestinoObrigatorio &&
          this.movimentacao.armazenamento == null) ||
        this.movimentacao.armazenamento == ""
      ) {
        ToastHelper.warningMessage(
          `Destino da movimentação deve ser informado ao preencher DU-E ou Declaração de Importação`
        );
        this.inputDestinoInvalido = true;
      } else {
        this.$emit("change", this.movimentacao);
      }
    },
    containerDuplicado(container) {
      for (
        var index = 0;
        index < this.movimentacao.containers.length;
        index++
      ) {
        var containerVerificacao = this.movimentacao.containers[index];
        if (
          container.numero != this.numeroContainerEdicao &&
          container.numero == containerVerificacao.numero
        ) {
          return container;
        }
      }
      return null;
    },
    saveContainer(container) {
      if (this.containerDuplicado(container) != null) {
        ToastHelper.warningMessage(
          `Container ${container.numero} já cadastrado.`
        );
      } else {
        if (this.loadedIndexContainer == null) {
          this.movimentacao.containers.push(container);
        } else {
          this.movimentacao.containers.splice(
            this.loadedIndexContainer,
            1,
            container
          );
          this.$refs.containerForm.reset();
          this.loadedIndexContainer = null;
        }
        this.$refs.containerForm.reset();
        this.loadedIndexContainer = null;
        this.numeroContainerEdicao = null;
      }
    }
  },
  created() {
    ArmazenamentoService.getAutorizadosEA().then(({ data }) => {
      this.optionsLocaisArmazenamentoEA = [];
      data.forEach(locaisArmazenamento => {
        this.optionsLocaisArmazenamentoEA.push({
          value: locaisArmazenamento.id,
          text: locaisArmazenamento.descricao
        });
      });

      if (this.mountedForm != null) {
        let local_armazenamento = {
          value: this.mountedForm.armazenamento?.id,
          text: this.mountedForm.armazenamento?.descricao
        };

        if (!this.optionsLocaisArmazenamentoEA.includes(local_armazenamento)) {
          this.optionsLocaisArmazenamentoEA.push(local_armazenamento);
        }
      }
    });
    if (this.mountedForm) {
      this.movimentacao.empresa = this.mountedForm.empresa;
      this.autoSelectEmpresa.items.push(this.movimentacao.empresa);
      this.movimentacao.dta = this.mountedForm.dta;
      this.movimentacao.containers = this.mountedForm.containers;
      this.movimentacao.due = this.mountedForm.due;
      this.movimentacao.declaracaoImportacao = this.mountedForm.declaracaoImportacao;
      if (
        this.mountedForm.armazenamento != null ||
        this.mountedForm.armazenamento != ""
      ) {
        this.movimentacao.armazenamento = this.mountedForm.armazenamento?.id;
      }
    }
  }
};
</script>

<style lang="scss">
.v-autocomplete .v-autocomplete-input-group .v-autocomplete-input {
  font-size: 1rem;
  font-weight: 400;
  padding: 0;
  box-shadow: none;
  border: 0;
  width: calc(100% - 30px);
  outline: none;
  background-color: transparent;
}

.v-autocomplete
  .v-autocomplete-input-group.v-autocomplete-selected
  .v-autocomplete-input {
  color: #495057;
  background-color: #ffffff;
}
.v-autocomplete .v-autocomplete-list {
  width: 100%;
  text-align: left;
  border: none;
  border-top: none;
  max-height: 400px;
  overflow-y: auto;
  border-bottom: 1px solid #cccccc;
  z-index: 1020;
}
.v-autocomplete .v-autocomplete-list .v-autocomplete-list-item {
  cursor: pointer;
  background-color: #fff;
  padding: 10px;
  border-bottom: 1px solid #cccccc;
  border-left: 1px solid #cccccc;
  border-right: 1px solid #cccccc;
}
.v-autocomplete .v-autocomplete-list .v-autocomplete-list-item:last-child {
  border-bottom: none;
}
.v-autocomplete .v-autocomplete-list .v-autocomplete-list-item:hover {
  background-color: #4aae9b;
  color: white;
}
.v-autocomplete {
  position: relative;
}
.v-autocomplete .v-autocomplete-list {
  position: absolute;
  top: 40px;
  left: 0;
}
.v-autocomplete .v-autocomplete-list .v-autocomplete-list-item {
  cursor: pointer;
}
.v-autocomplete
  .v-autocomplete-list
  .v-autocomplete-list-item.v-autocomplete-item-active {
  background-color: #4aae9b;
  color: white;
}
</style>
