const preencherCaractereEsquerda = ({ valor, total, caractere }) => {
    let mascara = new String(caractere);
    for (var i = 0; i < total; i++) {
        mascara = mascara + new String(caractere);
    }
    return (mascara + valor).slice(-total);
};

const toUpperCase = (valor) => {
    return valor.toUpperCase();
}

export default {
    preencherCaractereEsquerda,
    toUpperCase
};
